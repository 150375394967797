.media {
  aspect-ratio: unset;
}

.terminalWrapper {
  --terminal-spacing: var(--space-m);
  --terminal-background-color: var(
    --hero-illustration-background,
    var(--color-background-light)
  );

  position: relative;
  height: 100%;
  min-height: 200px;
  padding: var(--terminal-spacing);
  background: var(--terminal-background-color);
  background-image: linear-gradient(
    to right,
    var(--terminal-background-color) 30%,
    var(--terminal-gradient-color, var(--color-teal)) 200%
  );
}

.terminalWrapper.invertGradient {
  background-image: linear-gradient(
    to left,
    var(--terminal-background-color) 30%,
    var(--terminal-gradient-color, var(--color-teal)) 200%
  );
}

.logo {
  --logo-max-width: 70px;
  --logo-max-height: 30px;

  margin-top: var(--space-m-l);
  opacity: 0.5;
}

@media (--medium) {
  .terminalWrapper {
    --terminal-spacing: var(--space-l-xl);
  }

  .terminalWrapper > * {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: calc(100% - 2 * var(--terminal-spacing));
    max-height: calc(100% - 2 * var(--terminal-spacing));
    transform: translate(-50%, -50%);
  }
}

.claim {
  color: var(--color-text-default);
}

@media (--large) {
  .terminalWrapper {
    --terminal-spacing: var(--space-xl-2xl);
  }
}
