.media {
  aspect-ratio: unset;
}

.callToAction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
  min-height: min(80vh, 350px);
  padding: var(--space-m-l);
  color: var(--color-text-on-color);
}

.logo {
  width: 100px;
  height: auto;
  max-height: 40px;
  margin-top: 0;
  margin-bottom: auto;
  opacity: 0.5;

  & > svg {
    display: block;
    width: auto;
    height: 100%;
    max-height: 40px;
  }
}

.illustration {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.illustration > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  margin-top: var(--space-l);
}

@media (--medium) {
  .callToAction {
    padding: var(--space-l-xl);
  }
}
