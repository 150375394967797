.root {
  --offset-top: calc(
    var(--space-xl) + var(--nav-height) + var(--banner-height)
  );
  --hero-illustration-overlap: min(20vh, 150px);
}

.root.themeDark {
  --hero-background: var(--color-background-inverted);
  --hero-illustration-background: var(--color-background-light);
  --hero-color: var(--color-text-on-color);
  --hero-color-secondary: var(--base-color-grey-300);
}

.root.themeLight {
  --hero-background: var(--color-background-light);
  --hero-illustration-background: var(--color-background-inverted);
  --hero-color: var(--color-text-default);
  --hero-color-secondary: var(--color-text-secondary);
}

.headerContainer {
  position: relative;
  overflow: hidden;
  background-color: var(--hero-background);
  color: var(--hero-color);
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background: linear-gradient(
      270deg,
      transparent 0%,
      var(--hero-background) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.root.flipGradient .gradient {
  transform: rotateY(180deg);
}

.backgroundImage {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-size: cover;

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.backgroundIllustration {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.headline {
  font-size: fluid(44px, 72px);
}

.root.headingSizeSmall .headline {
  padding-right: var(--space-m);
  font-size: fluid(32px, 52px);
}

.subheading {
  margin-top: var(--space-m-l);
}

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--offset-top) var(--layout-gutter)
    calc(var(--hero-illustration-overlap) + var(--space-xl))
    var(--layout-gutter);
}

.eyebrow {
  color: var(--hero-color-secondary);
}

.media {
  position: relative;
  z-index: 2;
  margin: calc(-1 * var(--hero-illustration-overlap)) var(--layout-gutter) 0
    var(--layout-gutter);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background-color: var(--hero-illustration-background);
  aspect-ratio: var(--hero-media-aspect-ratio, 1.25 / 1);
}

.media > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.children {
  position: relative;
  z-index: 1;
  margin-top: var(--space-l);
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
}

.root.noChildren .children {
  padding-bottom: 0;
}

.intro {
  max-width: 45ch;
  font-family: var(--font-family-headline);
  font-size: fluid(20px, 24px);
  letter-spacing: -0.005em;
  line-height: 1.3;
}

.list {
  --tick-list-item-color: var(--color-text-default);

  margin-top: var(--space-l);
}

.callToAction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  min-height: min(80vh, 350px);
  padding: var(--space-m-l);
  color: var(--color-text-on-color);
}

.callToActionIllustration {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.callToActionIllustration > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.callToActionButtons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  margin-top: var(--space-l);
}

@media (--medium) {
  .root {
    --hero-illustration-overlap: 0px;

    position: relative;
  }

  .header {
    width: calc(50% - var(--column-gutter) / 2);
    min-height: var(--hero-min-height, min(100vh, 500px));
    padding-right: var(--space-m-l);
  }

  .media {
    position: absolute;
    top: var(--offset-top);
    right: 0;
    bottom: 0;
    width: calc(50% - var(--column-gutter) / 2);
    max-height: min(700px, calc(100vh - var(--offset-top)));
    margin: 0;
    margin-bottom: 0;
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
    aspect-ratio: unset;
  }

  .children {
    width: calc(50% - var(--column-gutter));
    min-height: var(--hero-min-overlap, var(--space-3xl));
    padding-top: 0;
  }

  .callToAction {
    padding: var(--space-l-xl);
  }
}

@media (--max) {
  .children {
    padding-right: var(--space-xl);
  }
}
