.root {
  --speaker-font-family: var(--font-family-body);
  --speaker-image-size: 64px;
  --speaker-name-size: var(--text-body-l);
  --speaker-subline-size: var(--text-body-s);

  display: flex;
  align-items: center;
  gap: var(--space-s);
  margin: 0;
  font-family: var(--speaker-font-family);
}

.imageWrapper {
  position: relative;
  width: var(--speaker-image-size);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  background: var(--color-background-light);
  aspect-ratio: 1 / 1;
}

.name {
  font-size: var(--speaker-name-size);
}

.subline {
  opacity: 0.75;
  font-size: var(--speaker-subline-size);
}

.subline > * {
  display: inline-block;
  margin-right: 0.25em;
}

/* VARIANTS */
.root[data-font='mono'] {
  --speaker-font-family: var(--font-family-mono);
}

.root[data-size='small'] {
  --speaker-image-size: 48px;
  --speaker-name-size: var(--text-body-s);
}

@media (--max) {
  .root {
    --speaker-image-size: 72px;
  }

  .root[data-size='small'] {
    --speaker-image-size: 64px;
    --speaker-name-size: var(--text-body-m);
    --speaker-subline-size: var(--text-body-s);
  }
}
