.root {
  --notch-size: 38px;
  --play-icon-background: rgba(255 255 255 / 80%);
  --play-icon-color: var(--color-accent-default);
  --action-color: var(--color-accent-default);
}

.coverWrapper {
  position: relative;
  width: 100%;
  margin: 0 0 var(--space-s);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  aspect-ratio: 16 / 9;
  clip-path: border-box;
}

/* VARIATION FOR LIGHT COVER */
.coverWrapper[data-theme='light'] {
  border: 1px solid var(--color-border-subdued);
}

.cover {
  width: 100%;
  height: 100%;
}

.playIcon {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  padding: 0;
  transform: translate(-50%, -50%);
  border: 0;
  background: var(--play-icon-background);
  color: var(--play-icon-color);
  font-size: 32px;
  clip-path: polygon(
    0% var(--notch-size),
    var(--notch-size) 0%,
    calc(100% - var(--notch-size)) 0%,
    100% var(--notch-size),
    100% calc(100% - var(--notch-size)),
    calc(100% - var(--notch-size)) 100%,
    var(--notch-size) 100%,
    0% calc(100% - var(--notch-size))
  );
}

.right {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--meta-margin-bottom);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.01em;
}

.metaList {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  gap: var(--meta-gap);
}

.metaTopics {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.metaListItem:not(:first-child)::before {
  content: '/';
  padding-right: var(--meta-gap);
  opacity: 0.5;
}

.metaDuration {
  margin-right: 0;
  margin-left: auto;
}

.title {
  max-width: 35ch;
  margin: 0;
  color: var(--color-text-default);
}

.text {
  max-width: 70ch;
  margin-top: var(--description-margin-top, var(--space-xs-s));
  color: var(--color-text-default);
  font-size: var(--text-body-l);
}

.details {
  margin-top: var(--space-l);
  color: var(--color-text-default);
}

.tickList {
  display: grid;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--space-2xs) var(--column-gutter);
}

.speakers {
  display: grid;
  gap: var(--row-gutter) var(--column-gutter);
}

.action {
  display: flex;
  gap: 0.25em;
  align-items: center;
  margin-top: var(--space-l);
  color: var(--action-color);
  font-family: var(--font-family-headline);
}

.date {
  display: flex;
  gap: 1ch;
}

/* INTERACTIVE */
a[href]:hover .root {
  --play-icon-background: var(--color-accent-default);
  --play-icon-color: var(--color-text-on-color);
  --action-color: var(--color-accent-hover);
}

a[href]:hover .title {
  color: var(--color-accent-hover);
}

/* VARIANTS */
.root[data-variant='primary'],
.root[data-variant='secondary'] {
  --meta-margin-bottom: var(--space-s);
  --meta-gap: var(--space-xs);
}

.root[data-variant='tertiary'] {
  --meta-margin-bottom: var(--space-xs);
  --meta-gap: var(--space-2xs);
}

@media (--medium) {
  .tickList,
  .speakers {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* RESPONSIVE */
@media (--large) {
  .root[data-variant='primary'] {
    --meta-margin-bottom: var(--space-xs);
    --description-margin-top: var(--space-m-l);

    display: flex;
    align-items: center;
    gap: var(--space-xl);
  }

  .root[data-variant='primary'][data-details='true'] {
    align-items: start;
  }

  .root[data-variant='primary'] .coverWrapper,
  .root[data-variant='primary'] .right {
    width: 50%;
  }

  .root[data-variant='primary'] .right {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (--max) {
  .root {
    --meta-margin-bottom: var(--space-m);
  }

  .root[data-variant='primary'],
  .root[data-variant='secondary'] {
    --meta-gap: var(--space-s);
  }

  .root[data-variant='primary'] {
    --meta-margin-bottom: var(--space-xs);
  }

  .root[data-variant='tertiary'] {
    --meta-gap: var(--space-xs);
    --meta-margin-bottom: var(--space-s);
  }
}
