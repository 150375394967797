.root {
  border-top: 1px solid var(--color-border);
}

.root[data-skip-border] {
  border-top: 0;
}

.header {
  padding-top: var(--space-s);
}

.root[data-skip-border] .header {
  padding-top: 0;
}

.title {
  margin: 0;
}

.main {
  margin-top: var(--space-m);
}
