.root {
  --webinar-cover-text-color: var(--color-text-default);
  --webinar-cover-gutter: min(5%, var(--space-m));
  --webinar-cover-speaker-row-gap: var(--space-s-m);
  --webinar-cover-speaker-column-gap: var(--space-l-xl);

  position: relative;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform var(--transition-default);
}

a[href]:hover .root .cover {
  transform: scale(1.0125);
}

.details {
  position: absolute;
  right: calc(2 * var(--webinar-cover-gutter));
  bottom: calc(1.75 * var(--webinar-cover-gutter));
  left: calc(1.5 * var(--webinar-cover-gutter));
  color: var(--webinar-cover-text-color);
}

.title {
  color: currentcolor;
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-xl);
  letter-spacing: -0.015em;
  line-height: 0.95;
}

.speakers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: var(--space-l) 0 0 0;
  padding: 0;
  font-size: var(--text-body-s);
  gap: var(--webinar-cover-speaker-row-gap)
    var(--webinar-cover-speaker-column-gap);
}

.speakerImage {
  position: relative;
  width: clamp(30px, 15%, 80px);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  background: var(--color-background-light);
  aspect-ratio: 1 / 1;
}

/* VARIANTS */
.root[data-theme='dark'] {
  --webinar-cover-text-color: var(--color-text-on-color);
}

.root[data-variant='reduced'] {
  --webinar-cover-speaker-gap: var(--space-m) var(--space-s);
  --webinar-cover-gutter: var(--space-xs);
}

/* RESPONSIVE */
@media (--small) {
  .title {
    font-size: var(--text-headline-2xl);
  }
}

@media (--medium) {
  .title {
    font-family: var(--font-family-headline);
    font-size: var(--text-headline-xl);
    letter-spacing: -0.02em;
    line-height: 1.05;
  }
}
