.root {
  --card-background-color: var(--color-background-light);
  --card-text-color: var(--color-text-default);

  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 400px;
  margin-right: var(--layout-gutter);
  margin-left: var(--layout-gutter);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.illustration svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transform: translate(-50%, -50%);
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background: linear-gradient(
      270deg,
      transparent 0%,
      var(--card-background-color) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.root.flipGradient .gradient {
  transform: rotateY(180deg);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    var(--card-background-color) 5%,
    transparent 100%
  );
}

.content {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  justify-content: flex-end;
  order: 2;
  padding: var(--space-l-xl);
}

.inner {
  width: 100%;
  max-width: 500px;
}

.subline {
  margin-top: var(--space-s);
}

.list {
  margin-top: var(--space-s);
  margin-bottom: var(--space-xs);
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s) var(--space-2xs);
  margin-top: var(--space-l);
}

/* VARIATIONS */
.root.dark {
  --card-background-color: var(--color-background-inverted);
  --card-text-color: var(--color-text-on-color);
}

.root.bleed {
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}

.root.bleed .content {
  padding-left: var(--layout-gutter);
}

.image {
  display: flex;
  flex-direction: column;
  align-items: end;
  max-height: content;
  margin-top: var(--space-l-xl);
  margin-right: var(--space-l-xl);
  margin-left: var(--space-l-xl);

  & img {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    object-position: bottom right;
  }
}

.root.bleed .image {
  padding-right: var(--layout-gutter);
}

.root.verticalAlignTop .content {
  justify-content: flex-start;
}

.root.alignCenter {
  align-items: center;
  justify-content: center;
  text-align: left;
}

.root.alignCenter .content {
  max-width: var(--layout-width-max);
  padding: var(--space-l);
}

.root.alignCenter .inner {
  display: flex;
  flex-direction: column;
  max-width: var(--layout-max-width-s);
  gap: var(--space-m);
  align-items: center;
}

.root.alignCenter .actions {
  display: flex;
  margin-top: 0;
  gap: var(--space-s);
}

.root.alignCenter.bleed .content {
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
}

.root.alignRight.bleed .content {
  padding-right: var(--layout-gutter);
}

.root.alignRight.bleed .image {
  margin-left: var(--layout-gutter);
  padding-right: 0;
}

.root.sizeSmall {
  min-height: 300px;
}

.root.sizeSmall .subline {
  margin-top: var(--space-xs);
}

.root.sizeSmall .list {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-2xs);
}

.root.verticalAlignTop .overlay {
  background: linear-gradient(
    to bottom,
    var(--card-background-color) 10%,
    transparent 80%
  );
}

@media (--medium) {
  .root {
    flex-direction: row;
    min-height: 500px;
  }

  .root.alignRight {
    flex-direction: row-reverse;
  }

  .content {
    order: 0;
  }

  .image {
    flex: 1;
  }

  .overlay {
    background: linear-gradient(
      to right,
      var(--card-background-color) -5%,
      transparent 100%
    );
  }

  .root.alignRight .overlay {
    background: linear-gradient(
      to left,
      var(--card-background-color) 5%,
      transparent 100%
    );
  }

  .root.verticalAlignTop:not(.alignRight) .overlay {
    background: linear-gradient(
      to right,
      var(--card-background-color) 5%,
      transparent 100%
    );
  }

  .root.sizeSmall {
    min-height: 300px;
  }

  .root.alignCenter .inner {
    flex-direction: row;
    gap: var(--space-xl);
  }
}

@media (--max) {
  .root {
    min-height: 620px;
  }

  .root.sizeSmall {
    min-height: 400px;
  }
}
