/* HORIZONTAL STACK */
.horizontal {
  display: grid;
  gap: var(--stack-space, var(--space-m));
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--stack-base-width, 300px), 1fr)
  );
}

/* VERTICAL STACK */
.vertical > * + * {
  margin-top: var(--stack-space, var(--space-m));
}

/* VARIANTS */
.root[data-space='3xs'] {
  --stack-space: var(--space-3xs);
}

.root[data-space='2xs'] {
  --stack-space: var(--space-2xs);
}

.root[data-space='xs'] {
  --stack-space: var(--space-xs);
}

.root[data-space='s'] {
  --stack-space: var(--space-s);
}

.root[data-space='m'] {
  --stack-space: var(--space-m);
}

.root[data-space='l'] {
  --stack-space: var(--space-l);
}

.root[data-space='xl'] {
  --stack-space: var(--space-xl);
}

.root[data-space='2xl'] {
  --stack-space: var(--space-2xl);
}

.root[data-space='3xl'] {
  --stack-space: var(--space-3xl);
}

.root[data-space='4xl'] {
  --stack-space: var(--space-4xl);
}
