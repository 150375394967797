.root > * {
  transform: translateY(40px);
  transition: opacity var(--transition-slow), transform var(--transition-slow);
  transition-delay: var(--stagger-delay);
  opacity: 0;
  will-change: opacity, transform;
}

.root.inView > * {
  transform: translateY(0);
  opacity: 1;
}

@media (--prefers-reduced-motion) {
  .root > * {
    transform: translateY(0);
    transition: none;
    opacity: 1;
  }
}
