.root {
  width: 100%;
  margin: 0;
  padding-top: var(--space-xs-s);
  padding-bottom: var(--space-xs-s);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background: var(--color-background-inverted);
  color: var(--color-text-on-color);
  font-family: var(--font-family-mono);
  font-size: fluid(11px, 13px);
}

.loading,
.done,
.command {
  display: flex;
  align-items: baseline;
  word-wrap: break-word;
}

.result {
  position: relative;
}

.loading,
.done {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  overflow: hidden;
  background: var(--color-background-inverted);
  color: rgb(255 255 255 / 75%);
}

.done {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
}

.commandGroup {
  padding: var(--space-2xs-xs) var(--space-s-m) var(--space-xs-s);
}

.commandGroup + .commandGroup {
  margin-top: 0.75em;
  border-top: 1px solid rgb(255 255 255 / 20%);
}

.doneIcon,
.loadingSpinner,
.prompt {
  margin-right: var(--space-2xs);
}

.doneIcon,
.loadingSpinner {
  display: inline-block;
  color: var(--color-accent-default);
  font-size: 1.25em;
}

.prompt {
  margin-right: var(--space-2xs);
  opacity: 0.5;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.75em;
  padding: var(--space-s-m) var(--space-s-m) var(--space-2xs-xs);
  border-top: 1px solid rgb(255 255 255 / 20%);
  gap: var(--space-2xs);
}

.action {
  width: 100%;
}

@media (--xsmall) {
  .actions {
    flex-direction: row;
  }

  .action {
    width: fit-content;
  }
}
